<template>
    <div>
        <full-overlay :show="loading" />
        <b-modal
            visible
            centered
            title="Вход"
            hide-header-close
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <b-form @submit.prevent="send">
                <b-form-group label="Логин">
                    <b-form-input v-model="login" required />
                </b-form-group>
                <b-form-group label="Пароль">
                    <b-form-input v-model="password" type="password" required />
                </b-form-group>
                <b-button type="submit">Отправить</b-button>
            </b-form>
        </b-modal>
    </div>
</template> 

<script>
import FullOverlay from "@c/FullOverlay.vue";
export default {
    name: "Login",
    components: {
        FullOverlay,
    },
    data: () => ({
        login: "",
        password: "",
        loading: false,
    }),
    methods: {
        async send() {
            this.loading = true;
            try {
                await this.$fetch("/login", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: this.login,
                        password: this.password,
                    }),
                }).then((r) => {
                    if (!r.ok) {
                        throw new Error("Ошибка авторизации");
                    }
                    return true;
                });
                this.$store.commit("auth/logined", true);
                this.$router.push({ name: "Home" });
            } catch (e) {
                this.$bvToast.toast(e.message, {
                    variant: "danger",
                });
            }
            this.loading = false;
        },
    },
};
</script>