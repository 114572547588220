<template>
    <b-overlay :show="show" no-wrap fixed z-index="9001" />
</template> 

<script>
export default {
    name: "FullOverlay",
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
};
</script>