<template>
    <b-jumbotron header="404" lead="Страница не найдена" border-variant="dark">
        <b-link :to="{ name: 'Home' }">На главную</b-link>
    </b-jumbotron>
</template> 

<script>
export default {
    name: "NotFound",
};
</script>