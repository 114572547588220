<template>
    <div>
        <div>Добро пожаловать в панель управления.</div>
        <div>Выберите в левом меню пункт для управления.</div>
    </div>
</template>

<script>
export default {
    name: "Home",
};
</script>
