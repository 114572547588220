import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@v/Home.vue';
import Login from '@v/Login.vue';
import PageNotFound from '@v/PageNotFound.vue';
import store from '@/store';
import { request } from '@/plugins/fetch';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Панель управления',
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Авторизация',
        }
    },
    {
        path: '/country',
        name: 'Country',
        meta: {
            title: 'Страны',
        },
        component: () => import('@v/Country.vue'),
    },
    {
        path: '/news',
        name: 'News',
        meta: {
            title: 'Новости',
        },
        component: () => import('@v/News.vue'),
    },
    {
        path: '/festival',
        children: [
            {
                path: '',
                name: 'Festival',
                meta: {
                    title: 'Список фестивалей',
                },
                component: () => import('@v/Festival.vue'),
            },
            {
                path: ':id',
                children: [
                    {
                        path: '',
                        name: 'FestivalCard',
                        meta: {
                            title: 'Карточка фестиваля',
                        },
                        props: route => ({
                            id: Number(route.params.id),
                        }),
                        component: () => import('@v/FestivalCard.vue'),
                    },
                    {
                        path: 'spectacle',
                        name: 'FestivalSpectacle',
                        meta: {
                            title: 'Спектакли фестиваля',
                            hideAdd: true,
                        },
                        props: route => ({
                            id: Number(route.params.id),
                        }),
                        component: () => import('@v/FestivalSpectacle.vue'),
                    },
                    {
                        path: 'place',
                        name: 'FestivalPlace',
                        meta: {
                            title: 'Места проведения фестиваля',
                        },
                        props: route => ({
                            id: Number(route.params.id),
                        }),
                        component: () => import('@v/FestivalPlace.vue'),
                    }
                ],
                component: () => import('@v/FestivalCardRoot.vue'),
            },
        ],
        component: {
            name: 'FestivalRoot',
            render: h => h('router-view'),
        }
    },
    {
        path: '/participant',
        children: [
            {
                path: '',
                name: 'Participant',
                meta: {
                    title: 'Участники'
                },
                component: () => import('@v/Participant.vue'),
            },
            {
                path: ':id',
                name: 'Spectacle',
                props: route => ({
                    id: Number(route.params.id),
                }),
                meta: {
                    title: 'Спектакли'
                },
                component: () => import('@v/Spectacle.vue'),
            }
        ],
        component: {
            name: 'ParticipantRoot',
            render: h => h('router-view'),
        },
    },
    {
        path: '/page',
        name: 'Page',
        meta: {
            title: 'Контент',
        },
        component: () => import('@v/Page.vue'),
    },
    {
        path: '*',
        meta: {
            title: 'Страница не найдена',
        },
        component: PageNotFound,
    }
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title ?? 'Панель управления';
    if (to.name != 'Login' && !store.state.auth.logined) {
        const { status } = await request("/test").then((r) => r.json());
        if (!status) {
            next({ name: 'Login' });
            return;
        } else {
            store.commit('auth/logined', true);
        }
    }
    next();
});

export default router;
