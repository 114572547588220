<template>
    <b-container id="app" class="pt-3">
        <aside v-if="$store.state.auth.logined">
            <b-link :to="{ name: 'Country' }">Страны</b-link>
            <b-link :to="{ name: 'Participant' }">Участники</b-link>
            <b-link :to="{ name: 'News' }">Новости</b-link>
            <b-link :to="{ name: 'Festival' }">Фестивали</b-link>
            <b-link :to="{ name: 'Page' }">Контент</b-link>
            <b-button @click="logout">Выход</b-button>
        </aside>
        <main>
            <router-view />
        </main>
    </b-container>
</template>

<style scoped>
#app {
    display: grid;
    grid-template-columns: 180px 1fr;
    gap: 20px;
}
aside {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
</style>

<script>
export default {
    methods: {
        logout() {
            this.$fetch("/logout");
            this.$store.commit("auth/logined", false);
            this.$router.push({ name: "Login" });
        },
    },
};
</script>