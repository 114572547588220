export function request(url, options) {
    return fetch(`${process.env.VUE_APP_API}${url}`, Object.assign({
        credentials: 'include',
    }, options));
}

export default {
    install(Vue) {
        /**
         * 
         * @param {RequestInfo} url 
         * @param {RequestInit} options 
         * @returns {Response}
         */
        Vue.prototype.$fetch = request;
        Vue.prototype.$fetchAdmin = (url, options) => request(`/admin${url}`, options);
    }
};
